import type { ShouldRevalidateFunction } from "@remix-run/react";

export const ignoreSearchParamsChanges: ShouldRevalidateFunction = ({
  defaultShouldRevalidate,
  formMethod,
  currentUrl,
  nextUrl,
}) => {
  if (
    (!formMethod || formMethod === "GET") &&
    nextUrl.pathname === currentUrl.pathname
  ) {
    return false;
  }
  return defaultShouldRevalidate;
};
